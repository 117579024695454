<template>
  <v-card>
    <div class="text-center mt-5 mb-5">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="indigo"
            dark
            v-bind="attrs"
            v-on="on"
          >
            Filter
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item class="mb-5">
              <v-list-item-title>
                <v-btn @click="handleLastWeekFilter">
                  Satu minggu terakhir
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item class="mb-5">
              <v-list-item-title>
                <v-btn @click="handleLastMonthFilter">
                  Satu bulan terakhir
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item class="mb-5">
              <v-list-item-title>
                <v-btn @click="customDate = !customDate">
                  Custom
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <div
            v-show="customDate"
            class=""
          >
            <div class="">
              <p class="text-center">
                Start date:
              </p>
              <v-row
                justify="center"
                class="mb-5"
              >
                <v-date-picker v-model="startDate"></v-date-picker>
              </v-row>
            </div>
            <div class="">
              <p class="text-center">
                End date:
              </p>
              <v-row
                justify="center"
                class="mb-5"
              >
                <v-date-picker v-model="endDate"></v-date-picker>
              </v-row>
            </div>
          </div>

          <v-divider></v-divider>
        </v-card>
      </v-menu>
    </div>
    <v-data-table
      :headers="headersTest"
      :page="page"
      :items="itemsTest"
      :search="searchInput"
      :loading="isLoading"
      hide-default-footer
      :disable-sort="true"
    >
    </v-data-table>
    <v-btn
      color="primary"
      @click="handlePush"
    >
      Tambah tanggal
    </v-btn>
    <p class="mt-5 text-center">
      Filter : {{ startDate }} - {{ endDate }}
    </p>
  </v-card>
</template>

<script>
import {
  mdiMagnify, mdiPlus, mdiTrashCanOutline, mdiPencilBoxMultipleOutline, mdiFilter,
} from '@mdi/js'
import toCurrencyFormat from '@/utils/currency/toCurrencyFormat'

export default {
  name: 'DataTablePagination',
  props: {
    // items: {
    //   type: Array,
    //   default: () => [],
    // },
    // headers: {
    //   type: Array,
    //   default: () => [],
    // },
    totalItems: {
      type: Number,
      default: () => 0,
    },
    title: {
      type: String,
      default: () => '',
    },
    subtitle: {
      type: String,
      default: () => '',
    },
    chip: {
      type: String,
      default: () => '',
    },
    addBtn: {
      type: String,
      default: () => '',
    },
    actionBtn: {
      type: String,
      default: () => '',
    },
    filters: {
      type: Array,
      default: () => [],
    },
    filterReturnObject: {
      type: Boolean,
      default: () => false,
    },
    search: {
      type: String,
      default: () => '',
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    totalPages: {
      type: Number,
      default: () => 0,
    },
    src: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      customDate: false,
      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      page: 1,
      searchInput: '',
      checkbox: false,
      button: '',
      icons: {
        mdiMagnify,
        mdiTrashCanOutline,
        mdiPencilBoxMultipleOutline,
        mdiPlus,
        mdiFilter,
      },
      showFilter: false,
      items: [
        {
          field: 'tes',
          '1 jan': 'halloo',
          '2 jan': 'helloo',
          '3 jan': 'helloo',
          '4 jan': 'hellooooooo',
        },
        {
          field: 'tes2',
          '1 jan': 'lorem',
          '2 jan': 'ipsum',
          '3 jan': 'dolor',
          '4 jan': 'sit',
        },
        {
          field: 'tes3',
          '1 jan': 'lorem',
          '2 jan': 'ipsum',
          '3 jan': 'dolor',
          '4 jan': 'sit',
        },
      ],
      headers: [
        { text: 'field', value: 'field' },
        { text: '1 jan', value: '1 jan' },
        { text: '2 jan', value: '2 jan' },
        { text: '3 jan', value: '3 jan' },
        { text: '4 jan', value: '4 jan' },
      ],
      itemsTest: [
        {
          name: 'lorem',
          status: 'active',
          price: '100000',
          date: '1 jan',
        },
        {
          name: 'ipsum',
          status: 'active',
          price: '12000',
          date: '2 jan',
        },
        {
          name: 'dolor',
          status: 'inactive',
          price: '15000',
          date: '3 jan',
        },
        {
          name: 'sit',
          status: 'inactive',
          price: '11000',
          date: '4 jan',
        },
      ],
      headersTest: [{ text: 'field', value: 'field' }],
    }
  },
  computed: {
    openDialog() {
      if (this.$listeners['open-dialog']) {
        return true
      }

      return false
    },
    searchQuery: {
      get() {
        return this.search
      },
      set(val) {
        this.searchInput = val
      },
    },
  },
  watch: {
    page: {
      handler() {
        this.$emit('change-page', this.page)
      },
    },
  },
  mounted() {
    this.pivotingTable()
  },
  methods: {
    handleFilter(event, emitName) {
      this.$emit(emitName, event)
    },
    priceFormat(val) {
      return `Rp. ${toCurrencyFormat(val)}`
    },
    pivotingTable() {
      let pivotItem = [{ field: 'name' }, { field: 'status' }, { field: 'price' }]
      this.itemsTest.forEach(item => {
        this.headersTest.push({
          text: item.date,
          value: item.date,
        })
        Object.entries(item).forEach(([key, value]) => {
          const data = pivotItem.filter(itemPivot => itemPivot?.field === key)[0]
          if (data) {
            data[item.date] = value
            pivotItem = pivotItem.filter(i => i?.field !== key)
            pivotItem.push(data)
          }
        })
      })
      this.itemsTest = pivotItem
    },
    handlePush() {
      const data = {
        name: 'amet',
        status: 'inactive',
        price: '11000',
        date: '5 jan',
      }
      this.headersTest.push({ text: data.date, value: data.date })
      this.itemsTest.map(item => {
        item[data.date] = data[item.field]
        this.$set(item, data.date, data[item.field])

        return item
      })
    },
    handleLastMonthFilter() {
      const date = new Date()
      const lastMonth = new Date(date.setDate(date.getDate() - 30)).toISOString().substr(0, 10)

      this.startDate = new Date(Date.now()).toISOString().substr(0, 10)
      this.endDate = lastMonth
    },
    handleLastWeekFilter() {
      const date = new Date()
      const lastMonth = new Date(date.setDate(date.getDate() - 7)).toISOString().substr(0, 10)

      this.startDate = new Date(Date.now()).toISOString().substr(0, 10)
      this.endDate = lastMonth
    },
  },
}
</script>

<style>
</style>
